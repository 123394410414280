export const environment = {
  production: true,
  baseUrl: "https://software.group-activa.com:44305/activa_server",
  idleBaseUrl: "https://software.group-activa.com:44305/notify",
  faceUrl: "https://activaapimanagement.azure-api.net/facial/",
  autoService: "https://sh-ci-staging-costapp.azurewebsites.net/#/", //"https://software.group-activa.com:44305/costapps/#/step-one/",
  riskService:
    "https://software.group-activa.com:44305/costapps/#/multirisque-habitation-step1/",
  condoleanceService:
    "https://software.group-activa.com:44305/costapps/#/condoleance-stepOne/",
  ladyStartupService:
    "https://software.group-activa.com:44305/costapps/#/ladyStartUp-step1/",
  chatbotService: "https://activa-chatbot.sevengps.net/full/#/seven/load",
  getInsurrance:
    "https://software.group-activa.com:44305/costapps/#/all-assurance/",
  googleTrackingId: "UA-199007566-1",
  loginUrl:
    "https://asurecover-backend-gui-prod.azurewebsites.net",
  automobileBaseUrl:
    "https://activaapimanagementprod.azure-api.net/tarifiktor-prod-gui",
  subscriptionBaseUrl:
    "https://activaapimanagementprod.azure-api.net/souscription-prod-gui",
  printingBaseUrl:
    "https://activaapimanagementprod.azure-api.net/docgenerator-prod-gui",
  automobileExtraBaseUrl:
    "https://asurecover-backend-gui-prod.azurewebsites.net",
  assureBaseUrl:
    "https://activaapimanagementprod.azure-api.net/activaorassapiprod",
  assureAuthApiUser:
  "GUI-SH",
  autoAuthApiUser:
  "GUI-SH",
  assureAuthApiPassword: 
  "14cf85274653932df6d68e0acbaf1193",
  autoAuthApiPassword: 
  "2e3efd364a359a093f1b421ee898fc27",
  identProcess: 
  "GUI-SH",
  ocpApimSubscriptionKey:
  "807f4890563747e797465aa99323b54a"
};
